<template>
  <div>
    <div class="my-6 text-start">
      <h3 class="font-bold">{{ route.name }}</h3>
      <span class="text-sm text-gray-500"
        >Aqui você pode observar todos os itens relacionados ao
        {{ route.name.toLowerCase() }} como suas respectivas ações.
      </span>
    </div>

    <div class="flex flex-row items-center">
      <div class="w-full  p-2">
        <Input
          type="datalist"
          id="tipo"
          v-model="produtoSelected"
          label="Tipos"
          placeholder="Selecione um Tipo de Produto"
          :data="tipos"
          @keyupSearch="getProduto"
        />
      </div>
      <div class="flex mb-2 mt-2 ">
        <Button color="primary" :text="`Buscar`" @click="getProduto" />
      </div>
    </div>

    <hr class="my-9" />
    <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10">
      <div class="w-full text-start">
        <div class="w-full">
          <h3 class="font-bold">Informações de Sub Tipo Produto</h3>
          <span class="text-sm text-gray-500">
            Aqui você pode observar todos os dados relacionados aos Tipo Produto
            selecionado
          </span>
        </div>
        <div class="flex flex-col items-end m-4">
          <router-link :to="`/subtipoproduto/criar`">
            <Button color="primary" :text="`Criar novo subtipo `" />
          </router-link>
        </div>
        <div class="card" v-if="data">
          <DataTable
            :headers="headers"
            :data="data"
            :options="options"
            color="primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import CardFinanceiro from "../../components/Elements/CardFinanceiro.vue";
import Input from "../../components/Elements/Input.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute } from "vue-router";

export default {
  name: "SubTipos",
  components: {
    DataTable,
    Button,
    Input,
  },
  emits: ["getProduto"],
  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Name", value: "nome" },
      { key: "Ações", value: "" },
    ];
    const route = useRoute();
    const alert = inject("alert");
    const loader = inject("loading");
    const modal = inject("modal");
    const data = ref([]);
    const tipos = ref([]);

    const produtoSelected = ref("");

    const getProduto = async () => {
      loader.open();
      try {
        const obj = ref("");
        const [produtoSelecionado] = tipos.value.filter(
          (evento) => evento.label == produtoSelected.value
        );

        obj.value = produtoSelecionado.value;

        data.value = await GET(`subtipo_produto/${obj.value}/tipos`);

        if (data.value.length <= 0)
          alert.open(
            "Atenção!",
            `Não há SubTipos Relacionados a esse Tipo de Produto!`,
            "warning"
          );
      } catch (e) {
        alert.open("Erro", "Selecione um Tipo de Produto Valido", "danger");
      }
      loader.close();
    };
    onMounted(async () => {
      loader.open();

      try {
        const tipoRes = await GET("tipo_produto");

        tipoRes.forEach((tipo) =>
          tipos.value.push({ label: tipo.nome, value: tipo.id })
        );
      } catch (e) {
        alert.open("Erro", e, "danger");
      }

      loader.close();
    });

    const deleteItem = (dica) => {
      loader.open();
      const deleteFunction = async function () {
        try {
          loader.open();
          await DELETE(`subtipo_produto/${dica.id}`);
          data.value = data.value.filter((item) => item.id != dica.id);
          loader.close();
          alert.open("Sucesso!", `Item deletado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      };
      modal.open(
        "Atenção",
        "Deseja realmente excluir esse item?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    const options = [
      { icon: "pen", path: "/subtipoproduto/editar", title: "Editar item" },
      //{ icon: "trash", action: (param) => deleteItem(param), title: "Excluir item" },
    ];

    return {
      headers,
      data,
      options,
      route,
      getProduto,
      tipos,
      produtoSelected,
    };
  },
};
</script>

<style>
.btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
</style>